<template>
  <div class="caseEditor-container">
    <header class="header">
      <van-nav-bar
        title="编辑案例"
        left-arrow
        @click-left="handleBlack"
        safe-area-inset-top
      >
      </van-nav-bar>
    </header>
    <article class="case-info">
      <div class="case-info-top">
        <div class="case-info-title">案例名称</div>
        <div class="case-name">
          <van-field
            v-model="liewinCase.title"
            rows="2"
            autosize
            type="textarea"
            placeholder="输入你要添加的内容"
            show-word-limit
          />
        </div>
        <div class="case-info-title">案例封面</div>
        <image-upload
          v-if="loading"
          :busiId="caseId"
          :busiCat="'liewincase'"
          :groupId="'caseIndexPic'"
          :isRealDeleteImg="isRealDeleteImg"
          :newBusiId="newBusiId"
        >
        </image-upload>
        <div class="case-info-title">海报图</div>
        <div class="article_img">
          <image-upload
              v-if="loading"
              :busiId="caseId"
              :busiCat="`poster`"
              :groupId="`sharePosterPic`"
              :isRealDeleteImg="isRealDeleteImg"
              :newBusiId="newBusiId"
          ></image-upload>
        </div>
        <div class="case-info-title">小区名称</div>
        <van-cell-group>
          <van-field
            placeholder="请输入你要添加的内容"
            v-model="liewinCase.casePosition"
          />
        </van-cell-group>
        <div class="case-info-title">全景地址</div>
        <van-cell-group>
          <van-field
            placeholder="请输入你要添加的内容"
            v-model="liewinCase.panoramicUrl"
          />
        </van-cell-group>
      </div>
      <div class="case-info-mine">
        <div>
          <span>户型</span>
          <van-dropdown-menu
            :overlay="overlay"
            z-index="150"
            active-color="#1989fa"
          >
            <van-dropdown-item
              @change="onChange"
              v-model="houseType"
              :options="houseOption"
            />
          </van-dropdown-menu>
        </div>
        <div>
          <span>面积</span>
          <div class="area">
            <van-cell-group>
              <van-field
                v-model="liewinCase.area"
                placeholder="输入面积"
                type="number"
              />
            </van-cell-group>
            <span>m²</span>
          </div>
        </div>
      </div>
      <div class="case-info-bottom">
        <div class="case-info-title">设计理念</div>
        <div class="case-design">
          <van-field
            v-model="liewinCase.idea"
            rows="4"
            autosize
            type="textarea"
            placeholder="输入你要添加的内容(选填)"
            show-word-limit
          />
        </div>
        <div class="case_editor">
          <rich-editor
            v-if="loading"
            v-model="liewinCase.picDescription"
            :busiId="caseId"
            :busiCat="'liewincase'"
            :groupId="'caseContentPic'"
          ></rich-editor>
        </div>

        <div class="is-show-card">
          <div class="show-switch"><span>在我的名片显示</span><van-switch v-model="showInCard" @change="switchShowInCard" size="20px" inactive-color="#BFBFBF"/></div>
            <van-field
            label="名片排名"
            placeholder="数字越大越靠前"
            clearable
            type="number"
            v-model="liewinCase.cardSort"
            v-if="showInCard"
          />
        </div>
      </div>
      <!-- <div class="footer">
                <van-grid clickable :column-num="2" safe-area-inset-bottom>
                <van-grid-item ><van-button round type="primary" block  color="#BFBFBF" @click="handleBlack">返回</van-button></van-grid-item>
                <van-grid-item ><van-button round type="primary" block color="#FF6E01" @click="handleSaveCase(liewinCase)">保存</van-button></van-grid-item>
            </van-grid>
           </div> -->
      <div class="footer">
        <van-grid clickable :column-num="2" safe-area-inset-bottom>
          <van-grid-item
            ><van-button
              round
              type="primary"
              block
              color="#BFBFBF"
              @click="handleBlack"
              >返回</van-button
            ></van-grid-item
          >
          <van-grid-item
            ><van-button
              round
              type="primary"
              block
              color="#FF6E01"
              @click="handleSaveCase(liewinCase)"
              >保存</van-button
            ></van-grid-item
          >
        </van-grid>
      </div>
    </article>
    <!-- 删除 -->
    <div class="del-wrapper">
      <van-overlay :show="showDel" @click="show = false">
        <div class="wrapper" @click.stop>
          <div class="block">
            <p>删除</p>
            <p>确定删除该案例？</p>
            <van-grid clickable :column-num="2">
              <van-grid-item
                ><span @click="handelConfirm('cancel')"
                  >取消</span
                ></van-grid-item
              >
              <van-grid-item
                ><span @click="handelConfirm">确定</span></van-grid-item
              >
            </van-grid>
          </div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from "vue";
import ImageUpload from "../../components/FileUpload/ImageUpload.vue";
import RichEditor from "../../components/RichEditor.vue";
import {
  findCaseById,
  updateCase,
  deleteCase,
  findCaseType,
  isCaseBelongUser,
} from "../../api/case";
import { useRoute, useRouter } from "vue-router";
export default {
  components: {
    ImageUpload,
    RichEditor,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const houseType = ref();
    const state = reactive({
      showInCard:false,
      newBusiId: "",
      showDel: false,
      loading: false,
      typeLoading: false,
      overlay: false,
      isRealDeleteImg: false,
      caseId: route.query.caseId,
      typeItem: {},
      houseOption: [],
      liewinCase: {
        caseId: "",
        oldId: route.query.caseId,
        type: null,
        title: "",
        casePosition: "",
        area: "",
        idea: "",
        picDescription: "",
        panoramicUrl: "",
        isCardRecommend:null,
        cardSort:null,
      },
    });
     const switchShowInCard = (str)=>{
      console.log("开关卡关",str);
      if(str===true){
        state.liewinCase.isCardRecommend = 1
      }else{
        state.liewinCase.isCardRecommend = 0
      }
    }
    const getNewCaseId = async () => {
      let res = await isCaseBelongUser(route.query.caseId);
      state.isRealDeleteImg = res === route.query.caseId;
      state.newBusiId = res;
      state.liewinCase.caseId = res;
    };

    const loadCase = async () => {
      state.loading = false;
      let result = await findCaseById(state.caseId);
      await getNewCaseId();
      state.loading = true;
      state.liewinCase.title = result.title;
      state.liewinCase.casePosition = result.casePosition;
      state.liewinCase.type = result.type;
      state.liewinCase.area = result.area;
      state.liewinCase.idea = result.idea;
      state.liewinCase.picDescription = result.picDescription;
      state.liewinCase.panoramicUrl = result.panoramicUrl;
      state.liewinCase.isCardRecommend = result.isCardRecommend;
      state.liewinCase.cardSort = result.cardSort
      houseType.value = result.type;
      if(result.isCardRecommend === 1){
        state.showInCard = true
      }else{
        state.showInCard = false
      }
    };
    loadCase();
    const loadCaseType = async () => {
      let result = await findCaseType();
      console.log("户型", result);
      for (let i = 0; i < result.length; i++) {
        for (let key in result[i]) {
          if (key == "typeName") {
            result[i]["text"] = result[i][key];
            delete result[i][key];
          }
          if (key == "typeId") {
            result[i]["value"] = result[i][key];
            delete result[i][key];
          }
        }
      }
      state.houseOption = result;
      state.typeLoading = true;
    };
    loadCaseType();

    const handleSaveCase = async (params) => {
      if (state.isRealDeleteImg) {
        let result = await updateCase(params);
        console.log("修改案例：", result);
        if (result) {
          router.push({
            path: "/caseVisitors/caseDetails",
            query: {
              caseId: state.liewinCase.caseId,
              caseType: route.query.caseType,
              twoType: route.query.twoType,
            },
          });
        }
      } else {
        console.log(params);
        let res = await updateCase(params);
        console.log("创建成功", res);
         if (res) {
          router.push({
            path: "/caseVisitors/caseDetails",
            query: {
              caseId: state.liewinCase.caseId,
              caseType: route.query.caseType,
              twoType: route.query.twoType,
            },
          });
        }
      }

      // router.back()
    };
    const handleBlack = () => {
      router.back();
      // console.log("户型",houseType.value);
      // console.log("信息",state.liewinCase);
    };
    //下拉菜单事件
    const onChange = () => {
      state.liewinCase.type = houseType.value;
    };
    const onClickRight = () => {
      state.showDel = true;
    };
    const handelConfirm = async (confirm) => {
      if (confirm === "cancel") {
        state.showDel = !state.showDel;
      } else {
        let result = await deleteCase(state.caseId);
        console.log("删除成功", result);
        router.push({ path: "/caseVisitors" });
        state.showDel = !state.showDel;
        router.push({
          name: "caseIndex",
          params: { caseType: route.query.caseType },
        });
      }
    };
    return {
      ...toRefs(state),
      houseType,
      handleBlack,
      handleSaveCase,
      onClickRight,
      handelConfirm,
      onChange,
      switchShowInCard,
    };
  },
};
</script>
<style lang="scss" >
.caseEditor-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header {
    .van-nav-bar__arrow {
      color: #333333;
    }
    .van-nav-bar__title {
      color: #333333;
      font-weight: 600;
    }
    .van-nav-bar__text {
      color: #ff6e01;
    }
  }
  .case-info {
    flex: 1;
    overflow-x: hidden;
    background-color: #f1f3f6;
    .van-field {
      border-radius: 4px;
    }
    .case-info-title {
      font-size: 14px;
      font-weight: 600;
      text-align: left;
      padding-top: 12px;
      padding-bottom: 8px;
    }
    .case-info-top {
      padding: 0 16px;
      padding-bottom: 10px;
    }
    .case-info-mine {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      background-color: #fff;
      div {
        padding: 0 16px;
        //  height: 48px;
        line-height: 48px;
      }
      > div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        border-bottom: 0.5px solid #f7f7f7;
        .van-ellipsis {
          color: #999999;
        }
        .van-dropdown-menu {
          width: 100px;
          height: 48px;
          .van-dropdown-menu__bar {
            padding: 0;
            box-shadow: none;
          }
          .van-dropdown-menu__item {
            padding: 0;
            box-shadow: none;
            .van-ellipsis {
              padding: 0;
            }
          }
        }
      }
      > div:nth-child(2) {
        display: flex;
        justify-content: space-between;
        .area {
          display: flex;
          padding-right: 30px;
          > span {
            color: #999999;
          }
        }
        .van-field__control {
          color: #999999;
          text-align: center;
        }
        .van-cell-group {
          width: 60px;
        }
        .van-cell-group,
        .van-field__value,
        .van-field,
        .van-field__body {
          padding: 0;
          // width: 50px;
          text-align: center;
        }
      }
    }
    .case-info-bottom {
      padding: 0 16px;
      .case_editor {
        img{
          max-width: 100%;
          vertical-align: middle;
        }
        p{
          //margin: 0 !important;
          margin-top: 0 !important;
          margin-bottom: 0 !important;

        }
        text-align: left;
        font-size: 14px;
        // color: #999999;
        // border-radius: 6px;
      }
      .case-design {
        margin-bottom: 5px;
      }

      .is-show-card{
      .show-switch{
        color: black;
        font-size: 14px;
        font-weight: 600;
        padding: 0 16px;
        height: 44px;
        margin-top: 8px;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
       .van-cell {
        margin-top: 8px;
        border-radius: 4px;
      }
      .van-cell__title{
        font-size: 14px;
        font-weight: 600;
        color: black;
      }
    }

    }
    .footer {
      .van-button--round {
        border-radius: 8px;
        height: 44px;
        width: 160px;
      }
      .van-hairline {
        background-color: #f7f7f7;
      }
    }
  }
  //遮罩层
  .del-wrapper {
    .van-overlay {
      z-index: 102;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .block {
        font-size: 14px;
        width: 303px;
        height: 133px;
        border-radius: 10px;
        color: #333333;
        background-color: #fff;
        > div {
          > div:nth-child(1) {
            .van-hairline {
              border-radius: 0 0 0 10px;
              padding: 0;
              color: #999999;
            }
          }
          > div:nth-child(2) {
            .van-hairline {
              border-radius: 0 0 10px 0;
              padding: 0;
            }
            [class*="van-hairline"]::after {
              border-right: none;
            }
          }
        }
        [class*="van-hairline"]::after {
          border-bottom: none;
        }
        > p {
          font-weight: 600;
          font-size: 14px;
          margin: 17px 16px;
        }
        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 46px;
          // height: 100%;
          // width: 100%;
        }
      }
    }
  }
}
</style>
